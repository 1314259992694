html {
  
  padding:0;
  margin: 0;
  box-sizing: border-box;
  
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

body {
  overflow-y: scroll;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-stretch: expanded;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  
}


h1 {
  font-size: 18px;
  vertical-align: bottom;
  margin: 0;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 14px;
}
p {
  font-size: 12px;
  margin: 0;
  text-decoration: none;
}
a{
  text-decoration: none;
  color: inherit;
}
a:hover{
  color: grey;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    white-space: pre;
  color: blue;
}
.downloadButton{
  border: none;
  background-color: #fff;
}


.about-body{
  max-width: 576px;
}
.about-body-roser{
  max-width: 755px;
}








.nav-mobile{
font-size: 18pt;
}
.nav ul {
  list-style: none;
  padding: 0;
}
.nav-title{
  z-index: 2000;
}
.nav-title a{
  color: inherit;
  text-decoration: none; 
 
 
}

.nav-item a{
  color: inherit;
  text-decoration: none; 
}

.nav-item .link:hover {
  color: grey;
}

.nav-item .link:focus {
  color: rgb(218, 0, 0);
}

.nav-item .link-active {
  color: rgb(218, 0, 0);
}

.nav-item .link-active:hover {
  color: rgb(147, 0, 0);
}

.offcanvas{
  --bs-offcanvas-width: 100%;
  --bs-offcanvas-transition: transform 0.15s ease-out;

}
@media (min-width:600px) {

   
   
}
.indicatorsContainer{
    display: none;


}
.indicatorsContainer{
   
    display: flex;
    
    


}




.carouselIndicator{

    box-sizing: border-box;
display: inline-block;
padding: 4px;
margin-right: 10px;


border: 1px solid ;
 background-color: #fff;
 /* border-radius: 50%; */
 
}

.carouselIndicatorActive{
background-color: black;
border: 1px solid ;
}
.proj-title{
margin: 0;
}
.proj-details{
 color: rgb(100, 100, 100);
}


.proj-desc p{
  white-space: pre;
  text-wrap: balance;
  
}

.proj-media-text p{
  white-space: pre;
  text-wrap: balance;
  
}
/* .proj-desc a{
  display: block;
  padding-top: 5px;

   
 } */
.proj-desc a:hover{
 color: rgb(218, 0, 0);
  
}

.proj-media-text a{
  display: block;
  padding-top: 5px;

   
 }
.proj-media-text a:hover{
 color: rgb(218, 0, 0);
  
}

.whitespace{
  white-space: pre-line;
}

.quote{
  font-style:italic;
}

/* Content of projects */

/* .proj-title
.proj-details
.proj-featured
.proj-description
.proj-media




.subsection-gallery {
  max-height: 500px;
  position: relative;
  display: inline-grid;
  grid-column-gap: 30px;
} */

/* Text content of projects */
/* .subsection-text-block {
  width: 400px;
  margin-right: 30px;
  height: 500px;
  overflow-y: hidden;
  position: relative;
  display: inline;
  grid-row: 1;
} */

/* .subsection-text-tech {
  margin-top: 30px;
  color: grey;
} */

/* .subsection-text-lang1 {
  margin-top: 25px;
} */

/* .subsection-text-lang2 {
  margin-top: 25px;
  color: grey;
} */

/* Image content of projects */
/* .subsection-img {
  display: inline;
  grid-row: 1;
} */

/* Video content of projects */
/* .subsection-video {}

.subsection-audio {} */

/* YOUTUBE */

.proj-media-youtube
 {
	
	
  
  
}
.youtube{
  background-color: #000;
  
	
	width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content:center;
}



.youtube iframe {
	aspect-ratio: 16 / 9;
	width: 100%;
  height: 100%;
  
}


.soundcloud {
  height:100%;
}
.soundcloud iframe {
  height:100%;
}
/* .videoyt {
	aspect-ratio: 16 / 9;
	width: 100%;
  
} */

.proj-media-divider{
  display: block;
  margin: 12px;
}

.headerInsideText{
  margin-bottom: 10px;
}

.externalLink{
  margin-top: 10px;
}
